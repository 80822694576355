<template>
  <div class="privacy-policy">
    <div><h3>隐私政策</h3></div>
    <div><p>本版更新时间：2022年11月15日</p></div>
    <div><p>本版更新时间：2022年11月15日</p></div>
    <div><p>隐私政策及授权使用协议</p></div>
    <div>
      <h4>概述</h4>
      <p>
        人民中科（北京）智能技术有限公司（以下简称“人民中科”）郑重承诺重视并保护用户的个人信息。我们秉承“一切以用户价值为依归”的理念，致力于提升信息处理透明度，增强您对信息管理的便捷性，保障您的信息及通信安全。我们严格遵守法律法规，遵循以下隐私保护原则，为您提供更加安全、可靠的服务。
      </p>

      <p>
        本《隐私政策》旨在向您说明，在您使用我们的服务时，我们将如何收集、使用、储存、保护和分享您的个人信息，以及您所享有的权利。
      </p>

      <p>
        本《隐私政策》与您所使用的“风险发现”的服务息息相关，希望您仔细阅读《隐私政策》（以下简称“本政策”），详细了解我们对信息的收集、使用方式，以便您更好地了解我们的服务并作出适当的选择。
      </p>

      <p>
        若您确认使用我们的服务，请在“我已经阅读并理解《隐私政策及授权使用协议》的条款。”勾选，即表示您认同我们在本政策中所述内容。除另有约定外，本政策所用术语与《服务协议》中的术语具有相同的涵义。
      </p>

      <p>如您有问题，请联系我们。</p>

      <h4>信息收集</h4>

      <p>
        我们根据合法、正当、必要的原则，仅收集为您提供服务所必要的信息。包括：
      </p>

      <p>1、您在使用我们服务时主动提供的信息</p>

      <p>（1）您在注册使用时提供的姓名、电话、备注等信息。</p>

      <p>（2）您在使用定制服务服务所提供的姓名、电话、微信/QQ、备注等信息。</p>

      <p>我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。</p>

      <p>
        若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。
      </p>

      <p>
        若您主动提供您的个人敏感信息，即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。
      </p>

      <p>2、我们在您使用服务时获取的信息</p>
      <p>（1）设备信息。例如，设备型号、操作系统版本等信息。</p>

      <p>
        （2）位置信息。当您使用与位置有关的服务时，我们可能会记录您设备所在的位置信息，以便为您提供相关服务。我们可能会通过IP地址、GPS、WiFi或基站等途径获取您的地理位置信息。
      </p>

      <p>
        （3）其他相关信息。为了给您提供更好的服务，我们会收集相关信息。例如，相册、文件夹、iCloud（iOS版本）。
      </p>

      <p>
        （4）我们的产品基于DCloud uni-app(5+ App/Wap2App)开发， 应用运行期间需要收集您的设备唯一识别码（IMEI/android ID/DEVICE_ID/IDFA、SIM 卡 IMSI 信息、OAID）以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。详情内容请访问《DCloud用户服务条款》。
      </p>

      <p>
        （5）消息推送服务供应商：由即数天堂（北京）网络技术有限公司提供的集成型统一推送技术服务，我们可能会将您的设备平台、设备厂商、设备品牌、设备识别码等设备信息，应用列表信息、网络信息以及位置相关信息提供给即数天堂（北京）网络技术有限公司，用于为您提供消息推送技术服务。我们在向您推送消息时，我们可能会授权即数天堂（北京）网络技术有限公司进行链路调节，相互促活被关闭的SDK推送进程，保障您可以及时接收到我们向您推送的消息。详情内容请访问《个推用户隐私政策》。
      </p>

      <p>
        3、从第三方合作伙伴获取的信息。我们可能会获得您在使用第三方合作伙伴服务时所产生或分享的信息。例如，您在使用第三方合作伙伴服务时，我们会通过第三方合作伙伴获得您的相关信息。
      </p>
      <p>请您仔细阅读第三方合作伙伴服务的用户协议或隐私政策。</p>
      <h4>信息使用</h4>
      <p>
        我们严格遵守法律法规的规定及与用户的约定，将收集的信息用于以下用途。若我们超出以下用途使用您的信息，我们将再次向您进行说明，并征得您的同意。
      </p>
      <p>1、向您提供服务。</p>
      <p>2、满足您的个性化需求。例如，视频溯源、图片检索等。</p>
      <p>3、项目开发和服务优化。例如，通过您的使用和反馈，优化我们的服务。</p>
      <p>信息共享</p>
      <p>我们遵照法律法规的规定，对信息的分享进行严格的限制。</p>
      <p>1、经您事先同意，我们可能与第三方分享您的个人信息。</p>
      <p>
        2、随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方分享您的个人信息时，我们通过协议约定的方式要求第三方，按照不低于本政策所要求的标准继续保护或要求新的管理者继续保护您的个人信息。
      </p>
      <p>
        3、我们会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的城市热力图或行业洞察报告。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的信息，用于了解用户如何使用我们服务或让公众了解我们服务的总体使用趋势。
      </p>
      <p>5、我们可能基于以下目的披露您的个人信息：</p>
      <p>（1）遵守适用的法律法规等有关规定；</p>
      <p>（2）遵守法院判决、裁定或其他法律程序的规定；</p>
      <p>（3）遵守相关政府机关或其他法定授权组织的要求；</p>
      <p>（4）我们有理由确信需要遵守法律法规等有关规定；</p>
      <p>
        （5）为执行相关服务协议或本政策、维护社会公共利益，为保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途。
      </p>
      <h4>信息保护</h4>
      <p>
        我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。
      </p>
      <p>
        我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
      </p>
      <h4>未成年人保护</h4>
      <p>
        我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若您是18周岁以下的未成年人，在使用服务前，应事先取得您的家长或法定监护人的同意或在服务时留下您的家长或法定监护人的联系方式，我们会与之联系获取他们的态度，再决定是否继续提供服务。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过与我们联系。
      </p>
      <h4>适用范围</h4>
      <p>
        本风险发现产品的所有服务均适用本政策。但某些服务有其特定的隐私指引/声明，该特定隐私指引/声明更具体地说明我们在该服务中如何处理您的信息。如本政策与特定服务的隐私指引/声明有不一致之处，请以该特定隐私指引/声明为准。
      </p>
      <h4>适用例外</h4>
      <p>本《隐私政策》不适用于以下情况：</p>
      <p>通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；</p>
      <p>通过在我们服务中进行广告服务的其他公司或机构所收集的信息。</p>
      <h4>变更</h4>
      <p>
        我们可能适时修订本政策内容。如变更会导致您在本政策项下权利的实质减损，我们将在变更生效前，通过在页面显著位置提示或向您发送电子邮件等方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的政策约束。
      </p>
      <h4>联系我们</h4>
      <p>联系地址：北京经济技术开发区科谷一街8号院8号楼14层1401</p>
      <p>邮政编码：101100</p>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss">
.privacy-policy {
  h3 {
    text-align: center;
  }
}
</style>
